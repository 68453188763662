/* General */
$textLabelWidth: 1280px;
$textColor: silver;
body {
  background-color: Indigo;
  font-family: 'Roboto', sans-serif;
  background-image: url("images/background.jpg");
  color: $textColor;
  margin-top:0;
}

.center {
  text-align: center;
}

.header{
  text-align: center;
  margin: auto;
  width: $textLabelWidth;
  font-size: 40px;
  height: 60px;
  width: $textLabelWidth;
  background-image: url("images/headerBackground.png");  
}

.headerNav {
  width: 100%;
  height: 60px;
  background-image: url("images/headerBackground.png");  
  position: fixed;
}

.headerButton{
  font-size: 30px;
  color: $textColor;
  background-image: url("images/headerButtonBackground.png");  
  height: 60px;
  width: 200px;
  outline: none;
  border: 0;
}

.subheader{
  text-align: center;
  margin: auto;
  width: $textLabelWidth;
  font-size: 22px;
  font-weight: bold;
  background-image: url("images/headerBackground.png");  
}

.paragraphText{
  text-align: center;
  margin: auto;
  width: $textLabelWidth;
  font-size: 22px;
  background-image: url("images/headerBackground.png");  
}

/* unvisited link */
a:link {
  color: $textColor;
}

/* visited link */
a:visited {
  color: plum;
}

/* mouse over link */
a:hover {
  color: $textColor;
}

/* selected link */
a:active {
  color: $textColor;
}

.logo {
  padding-top: 70px;
}

.projectButton:hover {
  transform: scale(1.05);
}

.projectButton {
  border: 0;
  border-color: $textColor;
  height:200px;
  width:400px;
  margin-left:15px;
  margin-right:15px;
}

.koboldKabanaButton{
  background-image: url("images/koboldKabanaButton.png");
}

.translator{
  background-image: url("images/translatorButton.png");  
}

.unity {
  width: 960px;
  height: 600px;
}

.footer{
  text-align: center;
  margin: auto;
  width: $textLabelWidth;
  font-size: 18px;
}